/* .ant-modal,
.ant-modal-content {
    width: 100vw
} */

.ant-tour {
    max-width: 300px;
}

.ant-collapse-header-text {
    font-weight: 800;
    font-size: 20px;
    color: #179188;
    text-align: left;
}

.ant-btn.ant-btn-default.ant-btn-default:hover,
.ant-btn-default:focus {
    color: #fff;
    border-color: #fff;
}

.custom-map {
    width: 100%;
    height: 100%;
    position: relative;
}

.map-title {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
}


::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
    height: 5px;
}

/* This is the background of the scrollbar, the track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 5px;
}

/* This is the handle, the draggable scrolling element */
::-webkit-scrollbar-thumb {
    background: #179188;
    border-radius: 5px;
}

/* Hover effect for the handle */
::-webkit-scrollbar-thumb:hover {
    background: #3fa29b;
}

.mainBlock {
    height: 100dvh;
}