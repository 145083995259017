/* .search_box {
  width: 60%;
} */

.search_box > span > input {
  background-color: #272727 !important;
  border-color: #ffd130 !important;
  color: #ffffff !important;
  padding: 5px 11px;
}

.search_box > span > input:hover {
  border-right: 0 !important;
}

.search_box > span > input:focus {
  border-right: 0 !important;
}

.search_box > span > span {
  background-color: #272727 !important;
  border: 1px solid #ffd130 !important;
  border-left: 0 !important;
}

.search_box > span > span > button {
  background-color: #272727 !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  /* border-color: #FFD130!important; */
  color: #ffd130 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%!important; */
}
