.custom-steps.ant-steps-label-vertical.ant-steps-label-vertical
  .ant-steps-item-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  width: auto !important;
}

.custom-steps.ant-steps-label-vertical.ant-steps-label-vertical
  .ant-steps-item-description {
  font-size: 12px;
  white-space: nowrap;
  margin-top: 8px;
}

.custom-steps.ant-steps-label-vertical.ant-steps-dot .ant-steps-item-tail {
  margin: 0;
}

.custom-steps.ant-steps-label-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-left: -4px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #179188;
  border-color: #179188;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #a5e0db;
  border-color: #a5e0db;
}

