.ant-drawer .ant-drawer-title {
    color: #179188;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
}

.mainBlock {
    height: 100dvh;
}
