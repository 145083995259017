
.transparent-button {
    background: transparent;
    color: #ffffff;
    border: none; 
}

.inside-button {
    font-size: 20px;
    color: white;
}

.custom-width {
    width: 5rem; 
}

.custom-width2 {
    width: 2.8rem; 
}