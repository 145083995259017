.centered-title-modal .ant-modal-title {
    text-align: center;
    margin: 0 auto;
}

.video-container {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
}

.video-container video {
max-width: 100%;
max-height: 80vh; /* Optional: This limits the image height to 80% of the viewport height */
}



.tip-container {
    display: flex;
}