/* styles.module.css */
.liNotBold {
    font-weight: normal;
}

/* 在你的样式文件中或者在组件内部使用内联样式 */
ul {
    margin-top: 0; /* 可以尝试使用 0 来清除默认的 margin */
    padding-top: 0; /* 可以尝试使用 0 来清除默认的 padding */
    list-style-type: none; /* 可以移除默认的列表样式，以防止其产生额外的间隙 */
  }